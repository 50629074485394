import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Historic Preservation Fund | How revenue works",
  "includeToc": true,
  "description": "The Historic Preservation Fund (HPF) provides matching grants to state and tribal historic preservation offices to pay for surveys, training, and grants. The HPF is funded by revenue from offshore oil and gas leases.",
  "tags": ["How revenue works", "offshore", "conservation", "Gulf of Mexico", "disbursements", "historic", "grants", "HPF"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GlossaryTerm = makeShortcode("GlossaryTerm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works /`}</a></p>

    <h1 {...{
      "id": "Historic-Preservation-Fund",
      "style": {
        "position": "relative"
      }
    }}>{`Historic Preservation Fund`}</h1>
    <p>{`The Historic Preservation Fund (HPF) provides matching grants to state and tribal historic preservation offices to pay for surveys, training, and grants. The HPF is funded by revenue from offshore oil and gas leases.`}</p>
    <br />
Congress created the fund with the passage of the National Historic Preservation Act of 1966 and began funding it in 1976. The National Parks Service administers the HPF.
    <h2 {...{
      "id": "Funding-priorities",
      "style": {
        "position": "relative"
      }
    }}>{`Funding priorities`}</h2>
    <p>{`The fund provides grants to state and tribal historic preservation offices. These grants support the conservation of cultural and historic sites. The fund aligns with the goals of the National Historic Preservation Act, which authorizes the Secretary of the Interior to:`}</p>
    <ul>
      <li parentName="ul">{`maintain a National Register of Historic Places`}</li>
      <li parentName="ul">{`issue regulations for State Historic Preservation Programs`}</li>
      <li parentName="ul">{`award matching grants to states`}</li>
      <li parentName="ul">{`award matching grants to the `}<a parentName="li" {...{
          "href": "https://savingplaces.org/"
        }}>{`National Trust for Historic Preservation `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a></li>
    </ul>
    <p>{`In the act, Congress declared, "the preservation of this irreplaceable heritage is in the public interest so that its vital legacy of cultural, educational, aesthetic, inspirational, economic, and energy benefits will be maintained and enriched for future generations of Americans."`}</p>
    <h2 {...{
      "id": "Authorization-and-funding",
      "style": {
        "position": "relative"
      }
    }}>{`Authorization and funding`}</h2>
    <p>{`The Historic Preservation Fund is `}<GlossaryTerm mdxType="GlossaryTerm">{`authorized`}</GlossaryTerm>{` to receive $150 million annually through fiscal year 2023. The HPF is subject to congressional appropriations during the annual budget process. Actual appropriations to the HPF have varied over the years. Appropriations range from $54 million to $153 million between 2009 and 2020. Congress can appropriate supplemental funding in the event of natural disasters – such as hurricanes – as they did in 2013, 2018, and 2019.`}</p>
    <br />
The National Park Service publishes <a href="https://www.nps.gov/aboutus/budget.htm">annual budget justifications <OpenInNewIcon style={{
        fontSize: 20
      }} mdxType="OpenInNewIcon" /></a> (the "Green Book") detailing appropriations and spending.
    <h2 {...{
      "id": "Distribution-of-funds",
      "style": {
        "position": "relative"
      }
    }}>{`Distribution of funds`}</h2>
    <p>{`The National Parks Service allots HPF grants according to a formula specified in the National Historic Preservation Act. The formula determines the funding allocation based on the annual appropriated amount.`}</p>
    <h3 {...{
      "id": "Requirements",
      "style": {
        "position": "relative"
      }
    }}>{`Requirements`}</h3>
    <p>{`Eligible grant applicants must be one of the following:`}</p>
    <ul>
      <li parentName="ul">{`states operating approved State Historic Preservation Programs`}</li>
      <li parentName="ul">{`Indian tribes and Native Hawaiian organizations`}</li>
      <li parentName="ul">{`National Trust for Historic Preservation`}</li>
      <li parentName="ul">{`certified local governments where there is no approved state program`}</li>
    </ul>
    <p>{`Review the National Park Service's `}<a parentName="p" {...{
        "href": "https://www.nps.gov/subjects/historicpreservationfund/index.htm"
      }}>{`Historic Preservation Fund website `}<OpenInNewIcon style={{
          fontSize: 20
        }} mdxType="OpenInNewIcon" /></a>{`. This site provides more guidance on the grant application and approval process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      